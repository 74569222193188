import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";

const LocationPage = () => (
  <Layout backgroundColor="salmon">
    <Seo title="Location" />

    <h1>Covid Safety</h1>

    <p>
      <strong>Updated 9.11.21</strong>
    </p>

    <p>
      For the safety and comfort of our family and friends, we respectfully
      require all eligible guests to be fully vaccinated against COVID-19.
    </p>

    <p>Young children are welcome.</p>

    <p>FYI we will be a party of approximately 50.</p>

    <p>
      As of August 20th, most indoor activities in SF require proof of full
      vaccination. Please take a photograph of the front and back of your COVID
      vaccine card before leaving home! Otherwise, you will not be able to enter
      restaurants, bars, convenience stores, and many other public settings.
    </p>

    <p>
      The city requires everyone of eligibile age to send us proof of
      vaccination in advance. Look out for an email with instructions.
    </p>

    <p>
      The ceremony and cocktail hour will take place outdoors with seated dinner
      occurring indoors. Nopalito’s caterers and bartenders are required to be
      fully vaccinated and will be taking all relevant precautions.
    </p>

    <p>We do not plan to require rapid testing for attendees.</p>

    <p>
      At this time the venue requires everyone to wear masks indoors unless
      actively eating or drinking.
    </p>

    <p>
      Due to the uncertainties of the Delta variant we urge you to choose
      lodging with free cancellation and to book flexible travel options.
    </p>

    <p>
      We will be following all national and local recommendations regarding
      COVID-19 safety and will be updating this page with current information.
    </p>

    <p>
      For up to date information about COVID levels in San Francisco we
      recommend looking at the&nbsp;
       <a href="https://www.nytimes.com/interactive/2021/us/california-covid-cases.html">
        NYTimes page
      </a>{" "}
      for California which lists the case rate per 100,000 and the{" "}
      <a href="https://www.nytimes.com/interactive/2021/us/san-francisco-california-covid-cases.html">
        San Francisco county page
      </a>{" "}
      for a closer look at the daily case reporting.
    </p>

    <Cta url="/location/" text="Learn About the venue" />
  </Layout>
);

export default LocationPage;
